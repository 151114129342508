import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import PageProps from "../../models/PageProps.interface";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

//Form
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LoadingContext } from "../../context/loading.context";
//Component Import
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";

//Icons
import { IProfileSettingsModel } from "../../interface/Request/IProfileSettingsModel";
import dayjs from "dayjs";
import UserService from "../../services/user/user.service";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import { ToastContext } from "../../context/toast.context";
import { IAlertDialogConfig } from "../../interface/IAlertDialogConfig";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { useNavigate } from "react-router-dom";
import ApplicationHelperService from "../../services/ApplicationHelperService";
import {
  API_ERROR_STANDARD_MESSAGE,
  DEFAULT_ALERT_CONFIG,
} from "../../constants/DBConstants.constant";
import { HttpStatusCode } from "axios";

import AlertDialog from "../../components/alertDialog/alertDialog.component";
import { updateUserData } from "../../actions/auth.actions";

const ProfileSettings: FunctionComponent<PageProps> = ({ title }) => {
  const dispatch = useDispatch();
    const { setLoading } = useContext(LoadingContext);
  const [showPassword, setShowPassword] = React.useState(false);
  const { userInfo } = useSelector((state: any) => state.authReducer);
  const _userService = new UserService(dispatch);
  const { setToastConfig, setOpen } = useContext(ToastContext);
  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });
  const navigate = useNavigate();
  const _applicationHelperService = new ApplicationHelperService({});
  const updateUserSessionData = (data: any) =>
    dispatch<any>(updateUserData(data));
  useEffect(() => {
    document.title = title;
  }, []);

  const [profileUpdate, setProfileUpdate] = useState<IProfileSettingsModel>({
    firstName: userInfo.user.firstname,
    lastName: userInfo.user.lastname,
    phoneNumber: userInfo.user.phoneNumber,
    gender: userInfo.user.gender,
    email: userInfo.user.email,
    appId: userInfo.user.appId,
    referralCode: userInfo.user.referralCode,
    DOB: userInfo.user.dateOfBirth
      ? dayjs(userInfo.user.dateOfBirth)
      : dayjs("2022-04-17"),
    joinedOn: dayjs(userInfo.user.joinedOn),
    userId: userInfo.user.userId,
  });

  const handleSubmit = async () => {
    try {
      setLoading(true);
      var response: IApiResponse = await _userService.profileSettings(
        profileUpdate
      );
      if (!response.isSuccess) {
        setToastConfig(ToastSeverity.Error, response.message, true);
      } else {
        updateUserSessionData(response);
        setAlertConfig({
          description: response.message,
          toastSeverity: ToastSeverity.Success,
          isShow: true,
          callBack: () => {
            setAlertConfig(DEFAULT_ALERT_CONFIG);
            navigate("/dashboard");
          },
        });
      }
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
      console.error("Error:", error);
    }
    setLoading(false);
  };

  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          {/* <Box className="pageViewHead">
                        <Typography variant="h6">Profile Settings</Typography>
                    </Box> */}
          <Box className="pageViewBody commonScroll">
            <Box>
              <Grid container spacing={4}>
                <Grid item xs={12} md={12} lg={12}>
                  <Box className="commonCard">
                    <Card>
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6} lg={6}></Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            {/* <Box className="floatR">
                              <Typography className="floatL normalText">
                                Profile Active Status
                              </Typography>
                              <FormControlLabel
                                className="commonSwitch"
                                control={<Switch defaultChecked disabled />}
                                label=""
                              />
                            </Box> */}
                          </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={4} lg={4}>
                            <TextField
                              id="firstName"
                              label="First Name"
                              variant="outlined"
                              fullWidth
                              value={
                                profileUpdate.firstName
                                  ? profileUpdate.firstName
                                  : ""
                              }
                              onChange={(evt) =>
                                setProfileUpdate({
                                  ...profileUpdate,
                                  firstName: evt.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={4} lg={4}>
                            <TextField
                              id="lastName"
                              label="Last Name"
                              variant="outlined"
                              fullWidth
                              value={
                                profileUpdate.lastName
                                  ? profileUpdate.lastName
                                  : ""
                              }
                              onChange={(evt) =>
                                setProfileUpdate({
                                  ...profileUpdate,
                                  lastName: evt.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={4} lg={4}>
                            <TextField
                              id="phone"
                              label="Phone"
                              variant="outlined"
                              fullWidth
                              inputProps={{ maxLength: 12, inputMode: "numeric", pattern: "[0-9]*" }} 
                              //value={profileUpdate.phoneNumber}
                              value={
                                profileUpdate.phoneNumber
                                  ? profileUpdate.phoneNumber
                                  : ""
                              }
                              onChange={(evt) =>
                                setProfileUpdate({
                                  ...profileUpdate,
                                  phoneNumber: evt.target.value,
                                })
                              }
                              
                            />
                          </Grid>
                          <Grid item xs={12} md={4} lg={4}>
                            <FormControl fullWidth>
                              <InputLabel id="Gender">Gender</InputLabel>
                              <Select
                                labelId="Gender"
                                id="Gender"
                                label="Gender"
                                value={
                                  profileUpdate.gender
                                    ? profileUpdate.gender
                                    : ""
                                }
                                onChange={(evt: SelectChangeEvent) => {
                                  setProfileUpdate({
                                    ...profileUpdate,
                                    gender: evt.target.value,
                                  });
                                }}
                              >
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4} lg={4}>
                            <TextField
                              id="gmail"
                              label="Gmail"
                              variant="outlined"
                              fullWidth
                              value={
                                profileUpdate.email === "bhat@gmail.com"
                                  ? ""
                                  : profileUpdate.email
                              }
                              onChange={(evt) =>
                                setProfileUpdate({
                                  ...profileUpdate,
                                  email: evt.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={4} lg={4}>
                            <TextField
                              id="appId"
                              label="User Id"
                              variant="outlined"
                              fullWidth
                              disabled
                              value={profileUpdate.appId}
                            />
                          </Grid>
                          <Grid item xs={12} md={4} lg={4}>
                            <TextField
                              id="referrerId"
                              label="Referrer Id"
                              variant="outlined"
                              fullWidth
                              disabled
                              value={profileUpdate.referralCode}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            lg={4}
                            className="commonDatePickerTopSpacing"
                          >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  disabled={true}
                                  label="Joined On"
                                  className="commonDatePicker"
                                  value={profileUpdate.joinedOn}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardActions className="floatR">
                        <Button
                          variant="contained"
                          className="primaryFillBtn"
                          onClick={handleSubmit}
                        >
                          <span>Submit</span>
                        </Button>
                      </CardActions>
                    </Card>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
      </Box>
      {alertConfig && alertConfig.isShow && (
        <AlertDialog
          alertConfig={alertConfig}
          callBack={alertConfig.callBack}
        />
      )}
    </Box>
  );
};
export default ProfileSettings;
