import React, {
  FunctionComponent,
  useState,
  useContext,
  useEffect,
} from "react";
import PageProps from "../../models/PageProps.interface";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Box from "@mui/material/Box";
import { Grid, Typography, Switch } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
//Component Import
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";
//Modal
import Modal from "@mui/material/Modal";
//CSSImport
import "../help/help.screen.style.css";

//Form
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";

//Links
import Link from "@mui/material/Link";

//Icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailIcon from "@mui/icons-material/Mail";
import TwitterIcon from "@mui/icons-material/Twitter";
import { LoadingContext } from "../../context/loading.context";
import AuthService from "../../../src/services/lookup/lookup.service";
import { useNavigate } from "react-router-dom";
import { IApiResponse } from "../../../src/interface/Response/IApiResponse";
import { ToastContext } from "../../../src/context/toast.context";
import { Formik } from "formik";
import { IAlertDialogConfig } from "../../../src/interface/IAlertDialogConfig";
import LookupService from "../../services/lookup/lookup.service";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import AlertDialog from "../../components/alertDialog/alertDialog.component";
import {
  IHelpModel,
  IHelpModalList,
} from "../../interface/BusinessModels/IHelpModel";
import {
  API_ERROR_STANDARD_MESSAGE,
  DEFAULT_ALERT_CONFIG,
  RoleType,
} from "../../constants/DBConstants.constant";
import { HttpStatusCode } from "axios";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { AppConfig } from "../../constants/AppConfig.constant";
import { useDispatch } from "react-redux";
import { ILookupModel } from "../../interface/BusinessModels/ILookupModel";

const Help: FunctionComponent<PageProps> = ({ title }) => {
  const dispatch = useDispatch();
  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });
  const [tabValue, setTabValue] = React.useState("PENDING");
  const { userInfo } = useSelector((state: any) => state.authReducer);
  const _authService = new AuthService(dispatch);
  const [OpenHelpPopUp, setOpenHelpPopUp] = React.useState(false);
  const [helpList, setHelpList] = useState<IHelpModalList[]>([]);
  const _helpService = new LookupService(dispatch);
  const [whatsappLink, setWhatsappLink] = useState<string>("");
    const [whatsappPhone, setWhatsappPhone] = useState<string>("");
  const navigate = useNavigate();
  const { setToastConfig, setOpen } = useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);

  const UPDATE_HELP_INITIAL_VALUES = {
    type: "",
    description: "",
    subject: "",
    toEmail: "",
    id: 0,
    helpid: "",
    createdOn: "",
    isResolved: false,
    remarks: "",
    modifiedOn: "",
    modifiedBy: "",
  };

  const { user } = userInfo;

  const [helpPopUp, setHelpPopUp] = useState<IHelpModalList>(
    UPDATE_HELP_INITIAL_VALUES
  );

  const helpModel = {
    type: "",
    description: "",
    subject: "",
    toEmail: userInfo.user.email,
  };

  useEffect(() => {
    document.title = title;
    getContactHelpList();
  }, []);

useEffect(() => {
    getSocialConnectionSettings();

    async function getSocialConnectionSettings() {
      try {
        const socialConnectionSettings: IApiResponse = await _helpService.getSocialGameSettings();

        const whatsLink = socialConnectionSettings.response.filter(
          (x: ILookupModel) => x.key === "WHATSAPP_LINK"
        )[0].value;
        const whatsPhone = socialConnectionSettings.response.filter(
          (x: ILookupModel) => x.key === "AGENT_WHATSAPP"
        )[0].value;

        if (whatsLink !== "") {
          setWhatsappLink(whatsLink);
          setWhatsappPhone(whatsPhone);
        }
        setLoading(false);
      } catch (error) {
        if (
          error &&
          (error as any).response &&
          (error as any).response.status != HttpStatusCode.Unauthorized
        ) {
          setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
        }
        setLoading(false);
      }
    }
  }, []);
    
  const _handleContactHelpPage = async (values: IHelpModel) => {
    try {
      var response: IApiResponse = await _helpService.help(values);

      if (!response.isSuccess) {
        setToastConfig(ToastSeverity.Error, response.message, true);
      } else {
        setAlertConfig({
          description: response.message,
          toastSeverity: ToastSeverity.Success,
          isShow: true,
          callBack: () => {
            setAlertConfig(DEFAULT_ALERT_CONFIG);
            navigate("/help");
          },
        });
      }
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
      console.log(error);
    }
  };
  const handleToggle = () => {
    setHelpPopUp({ ...helpPopUp, isResolved: !helpPopUp.isResolved });
  };
  const getContactHelpList = async () => {
    try {
      setLoading(true);

      const offerResponse: IApiResponse = await _helpService.GethelpList();
      setHelpList(offerResponse.response as IHelpModalList[]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
    }
  };

  const _handleUpdateHelp = async (values: IHelpModalList) => {
    try {
      var response: IApiResponse = await _helpService.updateHelp({
        ...values,
        id: values != null ? +(values.id as number) : 0,
      });
      if (!response.isSuccess) {
        setToastConfig(ToastSeverity.Error, response.message, true);
      } else {
        setAlertConfig({
          description: response.response.message,
          toastSeverity: ToastSeverity.Success,
          isShow: true,
          callBack: () => {
            setAlertConfig(DEFAULT_ALERT_CONFIG);
            getContactHelpList();
            setHelpPopUp(UPDATE_HELP_INITIAL_VALUES);
          },
        });
      }
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
      console.log(error);
    }
  };

  const _openPopUpDetails = (items: IHelpModalList) => {
    setHelpPopUp({
      type: items.type,
      description: items.description,
      subject: "",
      toEmail: "",
      id: items.id,
      helpid: "",
      createdOn: "",
      isResolved: items.isResolved,
      remarks: items.remarks,
      modifiedOn: "",
      modifiedBy: "",
    });
    setOpenHelpPopUp(true);
  };
  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          {/* <Box className="pageViewHead">
                        <Typography variant="h6">Help</Typography>
                    </Box> */}
          <Box className="pageViewBody commonScroll">
            <Box>
              <Grid container spacing={1}>
                {[RoleType.Admin, RoleType.SuperAdmin].includes(
                  user.roleId
                ) && (
                  <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={(event, newValue) => setTabValue(newValue)}
                      >
                        <Tab label="Pending" value={"PENDING"} />
                        <Tab label="Completed" value={"COMPLETED"} />
                      </TabList>
                    </Box>
                    <TabPanel value={"PENDING"}>
                      <Grid container spacing={1}>
                        {helpList &&
                          helpList
                            .filter((x) => x.isResolved == false)
                            .map((item: IHelpModalList) => (
                              <Grid item sm={12} xs={12} md={6} lg={4} xl={3}>
                                <Box
                                  className="commonCard"
                                  onClick={() => _openPopUpDetails(item)}
                                  key={item.id}
                                >
                                  <Card>
                                    <CardContent>
                                      <Box className="commonBgCard saperatorSpacing">
                                        <Grid container spacing={2}>
                                          <Grid item xs={10} md={10} lg={10}>
                                            <Typography className="cardTitle whiteColor">
                                              {item.helpid}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                      <Box className="commonLabelValue blockContent">
                                        <Typography className="commonLabel">
                                          Description
                                        </Typography>
                                        <Typography className="commonValue">
                                          {item.description}
                                        </Typography>
                                      </Box>
                                      <Box className="commonLabelValue">
                                        <Typography className="commonLabel">
                                          Type
                                        </Typography>
                                        <Typography className="commonValue">
                                          {item.type}
                                        </Typography>
                                      </Box>
                                      <Box className="commonLabelValue">
                                        <Typography className="commonLabel">
                                          Email
                                        </Typography>
                                        <Typography className="commonValue">
                                          {item.toEmail}
                                        </Typography>
                                      </Box>
                                      <Box className="commonLabelValue">
                                        <Typography className="commonLabel">
                                          Subject
                                        </Typography>
                                        <Typography className="commonValue">
                                          {item.subject}
                                        </Typography>
                                      </Box>
                                      <Box className="commonLabelValue">
                                        <Typography className="commonLabel">
                                          Created On
                                        </Typography>
                                        <Typography className="commonValue">
                                          {item.createdOn}
                                        </Typography>
                                      </Box>
                                    </CardContent>
                                  </Card>
                                </Box>
                              </Grid>
                            ))}
                      </Grid>
                    </TabPanel>
                    <TabPanel value={"COMPLETED"}>
                      <Grid container spacing={1}>
                        {helpList &&
                          helpList
                            .filter((x) => x.isResolved == true)
                            .map((item: IHelpModalList) => (
                              <Grid item sm={12} xs={12} md={6} lg={4} xl={3}>
                                <Box
                                  className="commonCard"
                                  onClick={() => _openPopUpDetails(item)}
                                  key={item.id}
                                >
                                  <Card>
                                    <CardContent>
                                      <Box className="commonBgCard saperatorSpacing">
                                        <Grid container spacing={2}>
                                          <Grid item xs={10} md={10} lg={10}>
                                            <Typography className="cardTitle whiteColor">
                                              {item.helpid}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                      <Box className="commonLabelValue">
                                        <Typography className="commonLabel">
                                          Description
                                        </Typography>
                                        <Typography className="commonValue">
                                          {item.description}
                                        </Typography>
                                      </Box>
                                      <Box className="commonLabelValue">
                                        <Typography className="commonLabel">
                                          Type
                                        </Typography>
                                        <Typography className="commonValue">
                                          {item.type}
                                        </Typography>
                                      </Box>
                                      <Box className="commonLabelValue">
                                        <Typography className="commonLabel">
                                          Email
                                        </Typography>
                                        <Typography className="commonValue">
                                          {item.toEmail}
                                        </Typography>
                                      </Box>
                                      <Box className="commonLabelValue">
                                        <Typography className="commonLabel">
                                          Subject
                                        </Typography>
                                        <Typography className="commonValue">
                                          {item.subject}
                                        </Typography>
                                      </Box>
                                      <Box className="commonLabelValue">
                                        <Typography className="commonLabel">
                                          Created On
                                        </Typography>
                                        <Typography className="commonValue">
                                          {item.createdOn}
                                        </Typography>
                                      </Box>
                                      <Box className="commonLabelValue">
                                        <Typography className="commonLabel">
                                          Remarks
                                        </Typography>
                                        <Typography className="commonValue">
                                          {item.remarks}
                                        </Typography>
                                      </Box>
                                    </CardContent>
                                  </Card>
                                </Box>
                              </Grid>
                            ))}
                      </Grid>
                    </TabPanel>
                  </TabContext>
                )}

                {[RoleType.User, RoleType.Agent].includes(user.roleId) && (
                  <>
                    <Grid item xs={12} md={12} lg={12}>
                      <Box className="commonBgCard">
                        <Typography className="bannerTitle whiteColor">
                          How can we help you?
                        </Typography>
                        <Typography className="normalText whiteColor">
                          You can find some usefull links below to help you on
                          how to use the application.
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box className="commonCard">
                        <Card>
                          <Formik
                            initialValues={{ ...helpModel }}
                            onSubmit={(values, { setSubmitting }) =>
                              _handleContactHelpPage(values)
                            }
                          >
                            {({
                              values,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              setFieldValue,
                              /* and other goodies */
                            }) => (
                              <form onSubmit={handleSubmit}>
                                <CardContent>
                                  <Box>
                                    <Typography className="sectionTitle">
                                      Review Or Contact Us
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} md={12} lg={12}>
                                        <FormControl fullWidth>
                                          <InputLabel id="type">
                                            Type
                                          </InputLabel>
                                          <Select
                                            labelId="type"
                                            id="type"
                                            value={
                                              values.type
                                                ? values.type.toString()
                                                : ""
                                            }
                                            onChange={(
                                              evt: SelectChangeEvent
                                            ) => {
                                              setFieldValue(
                                                "type",
                                                evt.target.value
                                              );
                                            }}
                                            label="type"
                                          >
                                            <MenuItem value={"General"}>
                                              General
                                            </MenuItem>
                                            <MenuItem value={"Complaint"}>
                                              Complaint
                                            </MenuItem>
                                            <MenuItem value={"Compliment"}>
                                              Compliment
                                            </MenuItem>
                                            <MenuItem value={"Suggestion"}>
                                              Suggestion
                                            </MenuItem>
                                            <MenuItem value={"Help"}>
                                              Help
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={12} md={12} lg={12}>
                                        <TextField
                                          id="subject"
                                          label="Subject"
                                          onChange={handleChange}
                                          value={values.subject}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={12} lg={12}>
                                        <TextField
                                          id="description"
                                          onChange={handleChange}
                                          value={values.description}
                                          label="Description"
                                          multiline
                                          rows={4}
                                          fullWidth
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={12} lg={12}>
                                        <Button
                                          type="submit"
                                          variant="contained"
                                          className="primaryFillBtn width100"
                                          disabled={isSubmitting}
                                        >
                                          <span>Submit</span>
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </CardContent>
                                {alertConfig && alertConfig.isShow && (
                                  <AlertDialog
                                    alertConfig={alertConfig}
                                    callBack={alertConfig.callBack}
                                  />
                                )}
                              </form>
                            )}
                          </Formik>
                        </Card>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box className="height100">
                        <Card className="height100">
                          <CardContent>
                            {/* <Box className="cardBanner socialMedia">
                              <img
                                alt={`${AppConfig.applicationName} - Social Media`}
                                className="commonImage"
                                src={require("../../assets/images/help/socialMedia.png")}
                              />
                            </Box> */}
                            <Box>
                              <Typography className="sectionTitle">
                                Social Connections
                              </Typography>
                              <Typography className="normalText">
                                You can keep connect with us in any of the
                                following social connection.
                              </Typography>
                            </Box>
                              <div className="">
                                {/* <GoogleTranslate /> */}
                                {whatsappLink != "" && (
                                  <Link
                                    style={{ borderRadius: "12px" }}
                                    href={whatsappLink}
                                    className="socialMediaBtn1 whatsapp1"
                                    target="_blank"
                                  >
                                    <span className="socialMediaIcon">
                                       <img
                                                    alt={`${AppConfig.applicationName} - Whatsapp`}
                                                    src={require("../../assets/images/landingPage2V2/whatsapp.png")}
                                                  />
                                      {/* <WhatsAppIcon /> */}
                                    </span>
                                    <span>{whatsappPhone}</span>{" "}
                                  </Link>
                                )}
                              </div>
                            <Box
                              className="socialMediaLinks"
                              sx={{ display: "none" }}
                            >
                              <Box className="socialMediaType">
                                <Link
                                  href="https://www.facebook.com/"
                                  target="_blank"
                                >
                                  <FacebookIcon />
                                </Link>
                              </Box>
                              <Box className="socialMediaType">
                                <Link
                                  href="https://www.instagram.com/"
                                  target="_blank"
                                >
                                  <InstagramIcon />
                                </Link>
                              </Box>
                              <Box className="socialMediaType">
                                <Link
                                  href="https://web.telegram.org/.socialMediaLinks"
                                  target="_blank"
                                >
                                  <TelegramIcon />
                                </Link>
                              </Box>
                              <Box className="socialMediaType">
                                <Link
                                  href="https://www.youtube.com/"
                                  target="_blank"
                                >
                                  <YouTubeIcon />
                                </Link>
                              </Box>
                              <Box className="socialMediaType">
                                <Link
                                  href="https://www.whatsapp.com/"
                                  target="_blank"
                                >
                                  <WhatsAppIcon />
                                </Link>
                              </Box>
                              <Box className="socialMediaType">
                                <Link
                                  href="https://www.gmail.com/"
                                  target="_blank"
                                >
                                  <MailIcon />
                                </Link>
                              </Box>
                              <Box className="socialMediaType">
                                <Link
                                  href="https://www.twitter.com/"
                                  target="_blank"
                                >
                                  <TwitterIcon />
                                </Link>
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                      </Box>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </Box>
          {/* Modal Popup Starts Here */}
          {helpPopUp.id > 0 && (
            <Formik
              enableReinitialize
              initialValues={helpPopUp}
              onSubmit={(values, { setSubmitting }) => {
                _handleUpdateHelp(values);
              }}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Modal
                    className="commonModal"
                    open={OpenHelpPopUp}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box className="commonModalBox">
                      <Box className="commonModalBoxInner">
                        <Box id="modal-modal-title" className="commonModalHead">
                          <Grid container spacing={2}>
                            <Grid item xs={11} md={11} lg={11}>
                              <Typography className="commonLabel">
                                Is Resolved
                              </Typography>
                              <Typography className="modalTitle">
                                {helpPopUp.isResolved ? true : false}
                              </Typography>
                              <Switch
                                checked={helpPopUp.isResolved}
                                onChange={handleToggle}
                                color="primary"
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </Grid>
                            <Grid item xs={1} md={1} lg={1}>
                              <span className="modalClose">
                                <CloseRoundedIcon
                                  onClick={() => setOpenHelpPopUp(false)}
                                />
                              </span>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box
                          id="modal-modal-description"
                          className="commonModalBody"
                        >
                          <Box>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={12} lg={12}>
                                <Box className="commonLabelValue">
                                  <Typography className="commonLabel">
                                    Type
                                  </Typography>
                                  <Typography className="commonValue">
                                    {helpPopUp.type}
                                  </Typography>
                                </Box>
                              </Grid>

                              <Grid item xs={12} md={12} lg={12}>
                                <Box className="commonLabelValue">
                                  <Typography className="commonLabel">
                                    Description
                                  </Typography>
                                  <Typography className="commonValue">
                                    {helpPopUp.description}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={12} lg={12}>
                                <TextField
                                  id="remarks"
                                  label="Remarks"
                                  multiline
                                  maxRows={4}
                                  variant="outlined"
                                  fullWidth
                                  value={values.remarks}
                                  onChange={handleChange}
                                  defaultValue={values.remarks}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                        <Box id="modal-modal-footer" className="commonFooter">
                          <Stack spacing={2} direction="row">
                            <Button
                              type="submit"
                              variant="contained"
                              className="primaryFillBtn"
                              onClick={() => _handleUpdateHelp(values)}
                            >
                              <span>Submit</span>
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={() => setOpenHelpPopUp(false)}
                              className="primaryLineBtn modalClose"
                            >
                              <span>Cancel</span>
                            </Button>
                          </Stack>
                        </Box>
                      </Box>
                    </Box>
                  </Modal>
                </form>
              )}
            </Formik>
          )}
          {/* Modal Popup Ends Here */}
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
        {alertConfig && alertConfig.isShow && (
          <AlertDialog
            alertConfig={alertConfig}
            callBack={alertConfig.callBack}
          />
        )}
      </Box>
    </Box>
  );
};
export default Help;
