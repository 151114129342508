import { Dispatch } from "react";
import HttpHelperService from "../httpHelper.service";
import {
  ADMIN_TRANSACTIONS,
  AGENT_RECHARGE_LIST,
  TRANSACTION_HISTORY,
  USER_TRANSACTION_HISTORY,
} from "../../constants/EndPoints.constant";
import { ITransactionsRequest } from "../../interface/Request/ITransactionsRequest";
import { IAgentRechargeListRequest } from "../../interface/Request/IAgentRechargeListRequest";
import { UUID } from "crypto";
import { Action } from "redux";

class TransactionService {
  _httpHelperService;
  constructor(dispatch: Dispatch<Action>) {
    this._httpHelperService = new HttpHelperService(dispatch);
  }

  transactionHistory = async (
    transactionsRequestModel: ITransactionsRequest
  ) => {
    return await this._httpHelperService.post(
      TRANSACTION_HISTORY,
      transactionsRequestModel
    );
  };

  userTransactionHistory = async (
    userId: UUID | string,
    transactionsRequestModel: ITransactionsRequest
  ) => {
    return await this._httpHelperService.post(
      USER_TRANSACTION_HISTORY(userId),
      transactionsRequestModel
    );
  };

  agentRechargeList = async (agentRechargeList: IAgentRechargeListRequest) => {
    return await this._httpHelperService.post(
      AGENT_RECHARGE_LIST,
      agentRechargeList
    );
  };

  adminTransactions = async (fromDate: string, toDate: string) => {
    return await this._httpHelperService.get(
      ADMIN_TRANSACTIONS(fromDate, toDate)
    );
  };
}

export default TransactionService;
