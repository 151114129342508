import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import PageProps from "../../models/PageProps.interface";

//Libraries Import
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

//Component Import
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";

//Import Css
import "../agentRechargeList/agentRechargeList.screen.style.css";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { ILookupModel } from "../../interface/BusinessModels/ILookupModel";
import LookupService from "../../services/lookup/lookup.service";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import {
  DEFAULT_PAGESIZE,
  TRANSACTION_TYPE_CONFIG,
  API_ERROR_STANDARD_MESSAGE,
  TransactionType,
} from "../../constants/DBConstants.constant";
import { HttpStatusCode } from "axios";

import { IAgentRechargeListRequest } from "../../interface/Request/IAgentRechargeListRequest";
import Button from "@mui/material/Button";
import { LoadingContext } from "../../context/loading.context";
import TransactionService from "../../services/transaction/transaction.service";
import { IAgentRechargeListResponseModel } from "../../interface/Response/IAgentRechargeListResponseModel";
import Pagination from "@mui/material/Pagination";
import { IAgentRechargeListModel } from "../../interface/Response/IAgentRechargeListModel";
import { ToastContext } from "../../context/toast.context";
import { ToastSeverity } from "../../constants/toastSeverity.contants";

//Icons Import
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PendingRoundedIcon from "@mui/icons-material/PendingRounded";
import { useDispatch } from "react-redux";
import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

const AgentRechargeList: FunctionComponent<PageProps> = ({ title }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext);
  const _lookupService = new LookupService(dispatch);
  const _transactionService = new TransactionService(dispatch);
  const [transactionTypes, setTransactionTypes] = useState<ILookupModel[]>();
  const [agentRechargeListRequest, setAgentRechargeListRequest] = useState<
    IAgentRechargeListRequest
  >({
    transactionType: -1,
    pageNumber: 1,
    pageSize: DEFAULT_PAGESIZE,
  });
  const [agentRechList, setAgentRechList] = useState<
    IAgentRechargeListResponseModel
  >();
  const { setToastConfig, setOpen } = useContext(ToastContext);

  useEffect(() => {
    getTransactionTypeConfig();

    async function getTransactionTypeConfig() {
      setLoading(true);
      try {
        const transactionTypesResponse: IApiResponse = await _lookupService.getConfigs(
          TRANSACTION_TYPE_CONFIG
        );
        setTransactionTypes(
          transactionTypesResponse.response.filter(
            (x: ILookupModel) => x.id !== TransactionType.GamePlay
          ) as ILookupModel[]
        );
      } catch (error) {
        if (
          error &&
          (error as any).response &&
          (error as any).response.status != HttpStatusCode.Unauthorized
        ) {
          setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
        }
      }
      setLoading(false);
    }
  }, []);

  const getAgentRechargeList = async () => {
    try {
      setLoading(true);
      const response: IApiResponse = await _transactionService.agentRechargeList(
        agentRechargeListRequest
      );
      setAgentRechList(response.response as IAgentRechargeListResponseModel);
      setLoading(false);
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
    }
  };

  useEffect(() => {
    getAgentRechargeList();
  }, [agentRechargeListRequest]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setAgentRechargeListRequest({
      ...agentRechargeListRequest,
      pageNumber: value,
    });
  };

  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          <Box className="pageViewBody commonScroll">
            <Box className="commonBgCard commonBgColorCard saperatorSpacing">
              <Grid container spacing={1}>
                <Grid item xs={12} md={9} lg={9}>
                  <FormControl fullWidth>
                    <InputLabel id="gameStatus">Transaction Type</InputLabel>
                    <Select
                      className="fontBlack"
                      labelId="gameStatus"
                      id="gameStatus"
                      label="gameStatus"
                      value={agentRechargeListRequest.transactionType.toString()}
                      onChange={(evt: SelectChangeEvent) => {
                        setAgentRechargeListRequest({
                          ...agentRechargeListRequest,
                          transactionType: +evt.target.value,
                        });
                      }}
                    >
                      <MenuItem value={-1}>All</MenuItem>
                      {transactionTypes &&
                        transactionTypes.map((option: ILookupModel) => (
                          <MenuItem
                            key={option.id}
                            value={option.id.toString()}
                          >
                            {option.value}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3} lg={3} className="verticalCenter">
                  <FormControl fullWidth>
                    <Button
                      variant="contained"
                      className="primaryFillBtn sectionBtn floatR"
                      onClick={() => getAgentRechargeList()}
                    >
                      <span>
                        <DownloadForOfflineRoundedIcon className="customIcon" />{" "}
                        &nbsp;Get Results
                      </span>
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            {agentRechList &&
              agentRechList.agentRechargeLists &&
              agentRechList.agentRechargeLists.length > 0 && (
                <Box>
                  <Grid container xs={12}>
                    <Grid
                      container
                      xs={12}
                      justifyContent="space-between"
                      className="selectPagenation"
                    >
                      <Select
                        className="commonSelect"
                        labelId="country"
                        id="country"
                        label="Country"
                        onChange={(evt: SelectChangeEvent) => {
                          setAgentRechargeListRequest({
                            ...agentRechargeListRequest,
                            pageSize: +evt.target.value,
                          });
                        }}
                        value={agentRechargeListRequest.pageSize.toString()}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                      <Pagination
                        count={agentRechList.pagination.totalPages}
                        onChange={handleChange}
                        showFirstButton
                        showLastButton
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    {agentRechList.agentRechargeLists.map(
                      (x: IAgentRechargeListModel) => (
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={4}
                          key={x.transactionUIId}
                        >
                          <Box className="commonCard">
                            <Card>
                              <CardContent>
                                <Box className="commonBgCard saperatorSpacing">
                                  <Grid container spacing={2}>
                                    <Grid item xs={10} md={10} lg={10}>
                                      <Typography className="cardTitle whiteColor">
                                        {x.transactionUIId.toUpperCase()}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      md={2}
                                      lg={2}
                                      className="boxRight"
                                    >
                                      {x.transactionName === "Recharge" ? (
                                        <Box className="verificationStatus approved">
                                          <DoneRoundedIcon />
                                        </Box>
                                      ) : x.transactionName == "Deduct" ? (
                                        <Box className="verificationStatus declined">
                                          <CloseRoundedIcon />
                                        </Box>
                                      ) : (
                                        <Box className="verificationStatus pending">
                                          <PendingRoundedIcon />
                                        </Box>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Box>
                                <Box className="commonLabelValue">
                                  <Typography className="commonLabel">
                                    User Id
                                  </Typography>
                                  <Link
                                    href="/"
                                    onClick={(e) => {
                                      e.preventDefault(); // Prevent appending #
                                      navigate(
                                        `/userInformation?ref=${x.userId.toUpperCase()}`,
                                        {
                                          replace: true,
                                        }
                                      );
                                    }}
                                  >
                                    {x.appId}
                                  </Link>
                                </Box>
                                <Box className="commonLabelValue">
                                  <Typography className="commonLabel">
                                    User Email
                                  </Typography>
                                  <Typography className="commonValue">
                                    {x.userEmail}
                                  </Typography>
                                </Box>
                                <Box className="commonLabelValue">
                                  <Typography className="commonLabel">
                                    Is Admin Transaction
                                  </Typography>
                                  <Typography className="commonValue">
                                    <span></span>
                                    {x.isAdmin ? "Yes" : "No"}
                                  </Typography>
                                </Box>
                                <Box className="commonLabelValue">
                                  <Typography className="commonLabel">
                                    Recharge Amount
                                  </Typography>
                                  <Typography className="commonValue">
                                    <span></span>
                                    {x.amount}
                                  </Typography>
                                </Box>
                                <Box className="commonLabelValue">
                                  <Typography className="commonLabel">
                                    Type
                                  </Typography>
                                  <Typography className="commonValue">
                                    <span></span>
                                    {x.transactionName}
                                  </Typography>
                                </Box>
                                <Box className="commonLabelValue">
                                  <Typography className="commonLabel">
                                    Recharge On
                                  </Typography>
                                  <Typography className="commonValue">
                                    {x.createdOn}
                                  </Typography>
                                </Box>
                              </CardContent>
                            </Card>
                          </Box>
                          {/* <Box>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6} lg={4}>
                                
                              </Grid>
                            </Grid>
                          </Box> */}
                        </Grid>
                      )
                    )}
                  </Grid>
                </Box>
              )}
            {/*  */}
          </Box>
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default AgentRechargeList;
