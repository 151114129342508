import React, {
  FunctionComponent,
  useEffect,
  useState,
  useContext,
} from "react";
import { isReturnStatement } from "typescript";
import PageProps from "../../../models/PageProps.interface";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { useNavigate } from "react-router-dom";

//Component Import
import Account from "../../../components/account/account.component";

//Form
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

//Styles
import "../forgotPassword/forgotPassword.screen.style.css";
import { Formik } from "formik";
import * as yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import AuthService from "../../../services/auth/auth.service";
import AlertDialog from "../../../components/alertDialog/alertDialog.component";
import { ToastSeverity } from "../../../constants/toastSeverity.contants";
import { IApiResponse } from "../../../interface/Response/IApiResponse";
import { IAlertDialogConfig } from "../../../interface/IAlertDialogConfig";
import { ToastContext } from "../../../context/toast.context";
import {
  API_ERROR_STANDARD_MESSAGE,
  DEFAULT_ALERT_CONFIG,
} from "../../../constants/DBConstants.constant";
import { HttpStatusCode } from "axios";

import { useDispatch } from "react-redux";

const ForgotPassword: FunctionComponent<PageProps> = ({ title }) => {
  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const _authService = new AuthService(dispatch);
  const { setToastConfig, setOpen } = useContext(ToastContext);

  const InitialValues = {
    username: "",
  };

  const ForgotPasswordSchema = yup.object().shape({
    username: yup.string().required("User Id is required"),
  });

  const _handleForgotPassword = async (values: any) => {
    try {
      const isValid = await ForgotPasswordSchema.isValid(values);
      if (isValid) {
        const response: IApiResponse = await _authService.forgotPassword({
          ...values,
          username: values.username.toString(),
        });

        if (response.isSuccess) {
          setAlertConfig({
            description: response.message,
            toastSeverity: ToastSeverity.Success,
            isShow: true,
            callBack: () => {
              setAlertConfig(DEFAULT_ALERT_CONFIG);
              navigate("/login");
            },
          });
        } else {
          setToastConfig(ToastSeverity.Error, response.message, true);
        }
      }
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
      console.log(error);
    }
  };

  return (
    <Formik
      initialValues={{ ...InitialValues }}
      validationSchema={ForgotPasswordSchema}
      onSubmit={(values, { setSubmitting }) => _handleForgotPassword(values)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} className="height100">
          <Box className="pageMain accountMain">
            <Box className="pageView">
              <Box className="accountBody height100">
                <Grid container spacing={2} className="height100">
                  {/* <Grid item xs={12} md={7} lg={7}>
                    <Box className="accountBodyLeft">
                      <Account />
                    </Box>
                  </Grid> */}
                  <Grid item xs={12} md={12} lg={12}>
                    <Box className="accountBodyRight height100">
                      <Box className="commonCard">
                        <Card className="height100">
                          <CardContent>
                            <Typography className="accountTitle">
                              Forgot your password?
                            </Typography>
                            <Typography
                              className="normalText textDecorationNone commonBottomSpacing"
                              component="a"
                            >
                              Please enter your UserId. We will send an request
                              to admin for resetting your password. If you do
                              not have an account, please{" "}
                              <Button onClick={() => navigate("/signup")}>
                                <span>Click here</span>
                              </Button>{" "}
                              to setup a new account(it's free).
                            </Typography>
                            <Box marginTop={5}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg={12}>
                                  <TextField
                                    id="username"
                                    label="User Id"
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.username}
                                  />
                                  {errors.username && touched.username && (
                                    <FormHelperText className="errorMessage">
                                      {errors.username}
                                    </FormHelperText>
                                  )}
                                </Grid>
                              </Grid>
                            </Box>
                          </CardContent>
                          <CardActions>
                            <Button
                              type="submit"
                              variant="contained"
                              className="primaryFillBtn width100"
                            >
                              <span>Submit</span>
                            </Button>
                          </CardActions>
                        </Card>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
          {alertConfig && alertConfig.isShow && (
            <AlertDialog
              alertConfig={alertConfig}
              callBack={alertConfig.callBack}
            />
          )}
        </form>
      )}
    </Formik>
  );
};
export default ForgotPassword;
