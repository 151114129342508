import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import PageProps from "../../models/PageProps.interface";

//Gadgets
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Fab from "@mui/material/Fab";
import YouTube, { YouTubeProps } from "react-youtube";
//Gadgest - List

//Icons
import { useNavigate } from "react-router-dom";
//Component Import

import "../landingPageV2/landingPageV2.screen.style.css";
import PlayGameService from "../../services/playGame/playGame.service";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import { IHomepageGameDataResponseModel } from "../../interface/Response/IHomepageGameDataResponseModel";
import { AppConfig } from "../../constants/AppConfig.constant";
import LookupService from "../../services/lookup/lookup.service";
import { ILookupModel } from "../../interface/BusinessModels/ILookupModel";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import {
  API_ERROR_STANDARD_MESSAGE,
  LINKING_WHATSAPP,
  WHATSAPP_DEFAULT_MESSAGE,
} from "../../constants/DBConstants.constant";
import { HttpStatusCode } from "axios";

import CountdownTimer from "../landingPage/countdownTimer";
import { LoadingContext } from "../../context/loading.context";
import { ToastContext } from "../../context/toast.context";
import { useDispatch } from "react-redux";

//Icons
import ApplicationHelperService from "../../services/ApplicationHelperService";

const LandingPageV2: FunctionComponent<PageProps> = ({ title }) => {
  const dispatch = useDispatch();
  const [isMobileAppDownloadEnabled, setIsMobileAppDownloadEnabled] = useState<
    boolean
  >(false);
  const [whatsappLink, setWhatsappLink] = useState<string>("");
  const [whatsappPhone, setWhatsappPhone] = useState<string>("");
  const [lotteryVideo1, setlotteryVideo1] = useState<string>("");
  const [lotteryVideo2, setlotteryVideo2] = useState<string>("");
  const _lookupService = new LookupService(dispatch);
  const _applicationHelperService = new ApplicationHelperService({});
  const { setLoading, isWebView } = useContext(LoadingContext);
  const { setToastConfig } = useContext(ToastContext);
  // YouTube video options
  const opts = {
    height: "400",
    width: "100%",
    playerVars: {
      autoplay: 0, // Autoplay the video
      controls: 1, // Show player controls
      mute: 0, // Mute the video
      modestbranding: 1, // Remove YouTube logo from player
      fs: 1, // Show fullscreen button
      rel: 0, // Do not show related videos at the end
    },
  };

  const [homeGameData, setHomeGameDate] = useState<
    IHomepageGameDataResponseModel[]
  >();

  const navigate = useNavigate();
  // const { language, toggleLanguage } = useLanguage();
  const _playGameService = new PlayGameService(dispatch);
  // const content = {
  //   EN: {
  //     welcome: `Welcome to ${AppConfig.applicationName}!`,
  //     play: "Play Thailottery Online",
  //   },
  //   TH: {
  //     welcome: `ยินดีต้อนรับสู่ ${AppConfig.applicationName}!`,
  //     play: "เล่นหวยออนไลน์",
  //   },
  // };

  const handleWhatsAppClick = async () => {
    const linkData = _applicationHelperService.generateWhatsAppLink(
      isWebView,
      WHATSAPP_DEFAULT_MESSAGE,
      whatsappPhone
    );
    _applicationHelperService.sendMessageOnDeviceType(
      isWebView,
      LINKING_WHATSAPP,
      linkData
    );
  };

  useEffect(() => {
    getGameData();
    async function getGameData() {
      var response: IApiResponse = await _playGameService.getHomeGameData();
      var homepageData = response.response as IHomepageGameDataResponseModel[];
      setHomeGameDate(homepageData);
    }
  }, []);

  useEffect(() => {
    getSocialConnectionSettings();
    isMobileAppDownloadEnabled();

    async function isMobileAppDownloadEnabled() {
      const response: IApiResponse = await _lookupService.isAppDownloadEnabled();
      setIsMobileAppDownloadEnabled(
        (response.response as ILookupModel).value.toLowerCase() === "false"
          ? false
          : true
      );
    }

    async function getSocialConnectionSettings() {
      try {
        const youtube_regex = /^.*(youtu\.be\/|vi?\/|u\/\w\/|embed\/|\?vi?=|\&vi?=)([^#\&\?]*).*/;
        const socialConnectionSettings: IApiResponse = await _lookupService.getSocialGameSettings();

        const whatsLink = socialConnectionSettings.response.filter(
          (x: ILookupModel) => x.key === "WHATSAPP_LINK"
        )[0].value;
        const whatsPhone = socialConnectionSettings.response.filter(
          (x: ILookupModel) => x.key === "AGENT_WHATSAPP"
        )[0].value;

        const lotteryVideo1 = socialConnectionSettings.response.filter(
          (x: ILookupModel) => x.key === "YOUTUBE_LINK"
        )[0].value;

        if (lotteryVideo1) {
          const parsed = lotteryVideo1.match(youtube_regex);
          if (parsed && parsed[2]) {
            console.log(parsed[2]);
            setlotteryVideo1(parsed[2]);
          } else {
            setlotteryVideo1("");
          }
        }

        if (whatsLink !== "") {
          setWhatsappLink(whatsLink);
          setWhatsappPhone(whatsPhone);
        }
        setLoading(false);
      } catch (error) {
        if (
          error &&
          (error as any).response &&
          (error as any).response.status != HttpStatusCode.Unauthorized
        ) {
          setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
        }
        setLoading(false);
      }
    }
  }, []);

  const onPlayerReady: YouTubeProps["onReady"] = (event: any) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  return (
    <Box className="landingV2">
      <Box className="heroSection">
        <Container className="heroSectionContainer">
          <Box className="topNavbar">
            <Box>
              <img
                alt={AppConfig.applicationName}
                className="mainLogo"
                src={require("../../assets/images/common/logo.png")}
              />
            </Box>
            <Box className="topNavLinks">
              <Link href="#" className="linkNav">
                Home
              </Link>
              <Link href="#" className="linkNav">
                About Us
              </Link>
              {/* <Link href="#" className="linkNav">
                <GoogleTranslate />
              </Link> */}
              <div className="headerItem topWhatsappActionLanding">
                {/* <GoogleTranslate /> */}
                {whatsappLink != "" && (
                  <Link
                    style={{ borderRadius: "12px" }}
                    href={whatsappLink}
                    className="socialMediaBtn1 whatsapp1"
                    target="_blank"
                  >
                    <span className="socialMediaIcon">
                      <img
                        alt={`${AppConfig.applicationName}  - Whatsapp`}
                        className=""
                        src={require("../../assets/images/dashboard/whatsapp.png")}
                      />
                      {/* <WhatsAppIcon /> */}
                    </span>
                    <span>{whatsappPhone}</span>{" "}
                  </Link>
                )}
              </div>
            </Box>
            <Box>
              <Button
                variant="contained"
                className="primaryBtn btnRightSpacing"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/login");
                }}
              >
                Login
              </Button>
              <Button
                variant="outlined"
                className="secondaryBtn"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/signup");
                }}
              >
                Signup
              </Button>
            </Box>
          </Box>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <Box className="contentCenter heroSectionContent">
                  {/* <Typography className="secondaryTitle">
                    {AppConfig.applicationName} Lottery
                  </Typography> */}
                  <h1 className="mainTitle">
                    Welcome To {AppConfig.applicationName}
                  </h1>
                  <Typography className="commonText">
                    Here you can play different type of 2D, 3D... Upto 6D
                    lotteries(Thailand lottery (2D , 3UP , 6UP), Singapore
                    Lottery(TOTO), Malysia Lottery(4D, Magnum , TOTO),
                    Myanmar(Burma) Lottery(2D,4D), Philippines lottery(PCSO 4D).
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className="">
                <Box className="">
                  <img
                    alt={`${AppConfig.applicationName} - Flag`}
                    className="rotatingImage"
                    src={require("../../assets/images/landingPage2V2/bhatBalls.png")}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          //width: "100%", // Responsive width
          // maxWidth: "900px", // Restrict maximum width
          //aspectRatio: "3 / 1", // Maintain 3:1 aspect ratio
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            animation: "scroll 15s linear infinite",
            width: "fit-content",
          }}
        >
          {/* Static Image 1 */}
          <Box
            component="img"
            src={require("../../assets/images/landingPage2V2/banner1.jpg")} // Replace with your image path
            alt="Slide 1"
            sx={{
              width: "900px", // Match container width
              height: "300px", // Match container height
              objectFit: "cover", // Fill container without distortion
              flexShrink: 0,
            }}
          />
          {/* Static Image 2 */}
          <Box
            component="img"
            src={require("../../assets/images/landingPage2V2/banner2.jpg")} // Replace with your image path
            alt="Slide 2"
            sx={{
              width: "900px",
              height: "300px",
              objectFit: "cover",
              flexShrink: 0,
            }}
          />
          {/* Static Image 3 */}
          <Box
            component="img"
            src={require("../../assets/images/landingPage2V2/banner3.jpg")} // Replace with your image path
            alt="Slide 3"
            sx={{
              width: "900px",
              height: "300px",
              objectFit: "cover",
              flexShrink: 0,
            }}
          />
          <Box
            component="img"
            src={require("../../assets/images/landingPage2V2/banner4.jpg")} // Replace with your image path
            alt="Slide 3"
            sx={{
              width: "900px",
              height: "300px",
              objectFit: "cover",
              flexShrink: 0,
            }}
          />
          <Box
            component="img"
            src={require("../../assets/images/landingPage2V2/banner5.jpg")} // Replace with your image path
            alt="Slide 3"
            sx={{
              width: "900px",
              height: "300px",
              objectFit: "cover",
              flexShrink: 0,
            }}
          />
        </Box>

        <style>
          {`
      @keyframes scroll {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-2700px); /* Adjust for total width (900px * 3) */
        }
      }
    `}
        </style>
      </Box>

      <Box className="howToPlaySection">
        <Container>
          <Typography className="secondaryTitle text-center">
            A2Z LOTTO in 3 Steps
          </Typography>
          {/* <h2 className="mainTitle text-center">How to Win the Lottery</h2> */}
          {/* <Typography className="commonText text-center stepDescription">
            To play the A2z Lottery, If your number matches the winning one, you win
            a prize. Here's how to get started:
          </Typography> */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={4}>
              <Box className="playType">
                <h3 className="mainTitle">
                  <span className="count">01</span>Register & SignUp
                </h3>
                <Box className="playTypeBody">
                  <img
                    alt={AppConfig.applicationName}
                    className="playTypeImage"
                    src={require("../../assets/images/landingPage2V2/register.svg")}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Box className="playType">
                <h3 className="mainTitle">
                  <span className="count">02</span>Enter your lucky number
                </h3>
                <Box className="playTypeBody">
                  <img
                    alt={AppConfig.applicationName}
                    className="playTypeImage"
                    src={require("../../assets/images/landingPage2V2/play.svg")}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Box className="playType">
                <h3 className="mainTitle">
                  <span className="count">03</span>Try your luck
                </h3>
                <Box className="playTypeBody">
                  <img
                    alt={AppConfig.applicationName}
                    className="playTypeImage"
                    src={require("../../assets/images/landingPage2V2/win.svg")}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className="eventsSection saperationSection">
        <Container>
          <Grid container spacing={1}>
            {homeGameData &&
              homeGameData.length > 0 &&
              homeGameData.map((item: IHomepageGameDataResponseModel) => (
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="lotteryResult commonCard">
                    <Box className="commonBgCard">
                      <Typography className="gameDate cardTitle textCenter">
                        {item.title}
                      </Typography>

                      <Box className="lotteryNumbers">
                        <Box className="firstPrizeBox">
                          <Box className="prizeType textCenter">
                            First Prize
                          </Box>
                          <Box className="numberCircleGroup">
                            {item.firstPrize
                              .split("")
                              .map((x: string, index: number) => (
                                <span key={index} className="numberCircle">
                                  {x}
                                </span>
                              ))}
                          </Box>
                        </Box>
                        <Box>
                          <Grid container spacing={1}>
                            <Grid item xs={6} md={6} lg={6}>
                              <Box className="secondPrizeBox">
                                <Box className="prizeType textCenter">3-Up</Box>
                                <Box className="numberCircleGroup">
                                  {item.threeUpStraight
                                    .split("")
                                    .map((x: string, index: number) => (
                                      <span
                                        key={index}
                                        className="numberCircle"
                                      >
                                        {x}
                                      </span>
                                    ))}
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                              <Box className="thirdPrizeBox">
                                <Box className="prizeType textCenter">
                                  2-Down
                                </Box>
                                <Box className="numberCircleGroup">
                                  {item.twoDownStraight
                                    .split("")
                                    .map((x: string, index: number) => (
                                      <span
                                        key={index}
                                        className="numberCircle"
                                      >
                                        {x}
                                      </span>
                                    ))}
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box>
                          <Button
                            onClick={() =>
                              navigate("resultsHistory", {
                                state: item.gameId,
                              })
                            }
                            variant="text"
                            className="width100 allResultsList"
                          >
                            See All Lottery Results
                          </Button>
                        </Box>
                      </Box>
                      <Box className="nextDraw">
                        <Box className="prizeType textCenter">NEXT DRAW</Box>
                        <Typography className="prizeType">
                          {item.nextDrawDate}
                        </Typography>
                        <Box className="timer">
                          <CountdownTimer calcFor={item.nextDrawDateTime} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Container>
      </Box>
      <Box className="acceptedPaymentsSection boxSpacing">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <div className="scroll-container">
                <div className="scroll-content">
                  <Box className="wh100">
                    <Box className="mainPart">
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                    </Box>
                    <Box className="mainPart">
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                    </Box>
                  </Box>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Box className="">
                <h2 className="mainTitle">
                  Accepted <br /> Payment Methods
                </h2>
                <Box>
                  <Typography className="secondaryTitle">
                    Cards and Digital Wallets
                  </Typography>
                  <Typography className="commonText">
                    Secure options like Visa, MasterCard, and JCB are widely
                    accepted, while PayPal, Apple Pay, Google Pay, and Samsung
                    Pay offer fast, convenient payments for lottery
                    participants.
                  </Typography>
                </Box>
                <Box>
                  <Typography className="secondaryTitle">
                    Bank Transfers
                  </Typography>
                  <Typography className="commonText">
                    Direct bank transfers and eChecks are trusted methods, ideal
                    for larger ticket purchases or claiming winnings, ensuring
                    secure and smooth transactions.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className="socialMediaSection boxSpacing">
        <Box className="socialBg">
          <Box className="contentCenter">
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={4} className="contentCenter">
                <Box>
                  <img
                    alt={AppConfig.applicationName}
                    className="footerLogo"
                    src={require("../../assets/images/landingPage2V2/adult.png")}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                lg={8}
                container
                justifyContent="center"
              >
                <Box
                  sx={{
                    width: { xs: "90%", sm: "80%", md: "70%", lg: "60%" },
                    margin: "0 auto",
                    textAlign: "center",
                    padding: { xs: 3, sm: 4, md: 5 },
                    background: "linear-gradient(135deg, #6DD5FA, #FFFFFF)",
                    borderRadius: 2,
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    color: "#333",
                  }}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      fontSize: { xs: 24, sm: 28, md: 32 },
                      fontWeight: "bold",
                      marginBottom: 3,
                      color: "#004d99", // A deep blue for contrast
                    }}
                  >
                    Play Responsibly
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: 14, sm: 16, md: 18 },
                      lineHeight: 1.8,
                    }}
                  >
                    At The a2zlotto, we are deeply committed to Responsible
                    Gaming. We believe that lottery games should be an enjoyable
                    form of entertainment, and our mission is to provide a safe
                    and secure environment for our customers.
                    <br />
                    While playing lottery games is a leisure activity for many,
                    we understand that, for some, it can lead to problematic
                    behavior. That’s why we&apos;ve implemented a range of tools
                    and support measures to help players manage their play, set
                    limits, and take a break if needed.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        className="acceptedPaymentsSection boxSpacing"
        sx={{
          background: "linear-gradient(180deg, #141E30, #243B55)", // Section background
          color: "#ffffff",
          padding: { xs: 4, sm: 6, md: 8 },
        }}
      >
        <Container>
          <h2
            className="mainTitle"
            style={{
              textAlign: "center",
              marginBottom: "20px",
              fontSize: "2rem",
              background: "linear-gradient(90deg, #ff7eb3, #ff758c)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Responsible Gaming Guidelines
          </h2>
          <Typography
            className="commonText"
            sx={{
              fontSize: { xs: 16, sm: 18, md: 20 },
              textAlign: "center",
              lineHeight: 1.8,
              marginBottom: "40px",
            }}
          >
            Lottery games are games of chance, and outcomes are never
            guaranteed. Playing should be for entertainment only. To help you
            stay in control, we encourage you to follow these simple guidelines:
          </Typography>
          <Grid container spacing={4}>
            {[
              {
                title: "Only play with what you can afford to lose",
                text:
                  "It's essential to set limits and ensure your spending does not exceed what you are comfortable with.",
                gradient: "linear-gradient(135deg, #115189, #000000)",
                icon: "💰", // Money bag icon
              },
              {
                title: "Set time limits",
                text:
                  "Decide how much time you wish to spend playing and stick to it.",
                gradient: "linear-gradient(135deg, #115189, #000000)",
                icon: "⏳", // Hourglass icon
              },
              {
                title: "Establish a budget",
                text:
                  "Plan how much you are willing to spend and avoid exceeding your budget.",
                gradient: "linear-gradient(135deg, #115189, #000000)",
                icon: "📝", // Note or list icon
              },
              {
                title: "Avoid chasing losses",
                text:
                  "Accept that losses are a part of the game. Chasing losses can lead to harmful behaviour.",
                gradient: "linear-gradient(135deg, #115189, #000000)",
                icon: "🚫", // No entry icon
              },
              {
                title: "Know the rules",
                text:
                  "Make sure you are familiar with the rules of the games before playing to ensure you make informed decisions.",
                gradient: "linear-gradient(135deg, #115189, #000000)",
                icon: "📖", // Book icon
              },
              {
                title: "Balance your entertainment",
                text:
                  "Make sure lottery play is just one of many enjoyable activities in your life.",
                gradient: "linear-gradient(135deg, #115189, #000000)",
                icon: "⚖️", // Scale icon
              },
              {
                title: "Avoid playing under the influence",
                text:
                  "Gaming while under the influence of alcohol can impair judgment and lead to risky decisions.",
                gradient: "linear-gradient(135deg, #115189, #000000)",
                icon: "🍸", // Martini glass icon
              },
            ].map((item, index) => (
              <Grid item xs={12} sm={6} md={6} key={index}>
                <Box
                  sx={{
                    background: item.gradient,
                    borderRadius: 3,
                    padding: 3,
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                    color: "#ffffff",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <Typography
                    className="secondaryTitle"
                    sx={{
                      fontSize: 18,
                      fontWeight: "bold",
                      marginBottom: 1,
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      textShadow: "1px 1px 2px rgba(0,0,0,0.5)", // Subtle text shadow
                    }}
                  >
                    <span style={{ fontSize: "1.5rem" }}>{item.icon}</span>
                    {item.title}
                  </Typography>
                  <Typography
                    className="commonText"
                    sx={{
                      fontSize: 16,
                      lineHeight: 1.6,
                      textShadow: "1px 1px 2px rgba(0,0,0,0.5)", // Text shadow for readability
                    }}
                  >
                    {item.text}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {lotteryVideo1 && (
        <Box className="youtubeSection saperationSection">
          <Container>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12}>
                <Box className="">
                  <YouTube
                    videoId={lotteryVideo1}
                    opts={opts}
                    onReady={onPlayerReady}
                  />
                </Box>
              </Grid>
              {/* <Grid item xs={12} md={6} lg={6}>
                <Box className="">
                  <YouTube videoId={lotteryVideo2} opts={opts} />
                </Box>
              </Grid> */}
            </Grid>
          </Container>
        </Box>
      )}

      <Box className="footerSection">
        <Box className="footerSectionTop">
          <Box>
            <img
              alt={AppConfig.applicationName}
              className="footerLogo"
              src={require("../../assets/images/common/logo.png")}
            />
          </Box>
          {/* <Typography className="commonText footerInfo">
            The Thailand Lottery is a government-regulated lottery held twice a
            month, offering cash prizes, including a grand prize. Proceeds
            support social welfare programs in the country.
          </Typography> */}
          <Box className="footerLinksPart">
            <Link href="/agentRegistration" className="linkNav">
              Agent Registration
            </Link>
            <Link href="/rules" className="linkNav">
              Rules & Conditions
            </Link>
            {/* <Link href="/exchangeRates" className="linkNav">
              Exchange Rates
            </Link> */}
            <Link href="/resultsHistory" className="linkNav">
              All Lottery Game Results
            </Link>
          </Box>
          {isMobileAppDownloadEnabled && (
            <Box>
              <a href={"https://a2zlotto.com/A2zLotto.apk"}>
                <img
                  alt="Play Store"
                  className="btnRightSpacing"
                  src={require("../../assets/images/landingPage2V2/PlayStoreIcon.png")}
                />
              </a>

              <img
                alt="Play Store"
                className=""
                src={require("../../assets/images/landingPage2V2/AppStoreIcon.png")}
              />
            </Box>
          )}
        </Box>
        <Box className="footerSectionBottom">
          <Typography>
            © 2025 {AppConfig.applicationName}.COM, All Rights Reserved.
          </Typography>
        </Box>
      </Box>
      <Box className="fabBtn">
        <Fab
          color="secondary"
          aria-label="Whatsapp"
          onClick={handleWhatsAppClick}
        >
          <img
            alt={`${AppConfig.applicationName}  - Whatsapp`}
            src={require("../../assets/images/landingPage2V2/whatsapp.png")}
          />
        </Fab>
      </Box>
    </Box>
  );
};
export default LandingPageV2;
