import React, { FunctionComponent } from "react";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import { ClockLoader, PropagateLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";

//Css
import "../account/account.component.style.css";
import "../../index.css";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import NoAccountsRoundedIcon from "@mui/icons-material/NoAccountsRounded";
import { IRefferals } from "../../interface/BusinessModels/IRefferalsModel";
import HowToRegRoundedIcon from "@mui/icons-material/HowToRegRounded";
import { RoleType } from "../../constants/DBConstants.constant";
import Link from "@mui/material/Link";
import { useLocation, useNavigate } from "react-router-dom";
import ApplicationHelperService from "../../services/ApplicationHelperService";

const ReferralsComponent = (props: { refferal: IRefferals }) => {
  const { userInfo } = useSelector((state: any) => state.authReducer);
  const navigate = useNavigate();
  const _applicationHelperService = new ApplicationHelperService({});
  return (
    <Grid item xs={12} md={6} lg={4}>
      <Box className="commonCard">
        <Card>
          <CardContent>
            <Box className="commonBgCard saperatorSpacing">
              <Grid container spacing={2}>
                <Grid item xs={10} md={10} lg={10}>
                  <Typography className="cardTitle whiteColor"></Typography>
                </Grid>
                <Grid item xs={2} md={2} lg={2} className="padL0">
                  {props.refferal.isActive == true ? (
                    <Box className="verificationStatus verified">
                      <HowToRegRoundedIcon />
                    </Box>
                  ) : (
                    <Box className={`verificationStatus declined`}>
                      <NoAccountsRoundedIcon />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
            {/* <Box className="commonLabelValue">
            <Typography className="commonLabel">
              Email
            </Typography>
            <Typography className="commonValue">
              {refferal.email}
            </Typography>
          </Box> */}
            <Box className="commonLabelValue">
              <Typography className="commonLabel">Phone Number</Typography>
              <Typography className="commonValue">
                {props.refferal.phoneCode} {props.refferal.phoneNumber}
              </Typography>
            </Box>
            <Box className="commonLabelValue">
              <Typography className="commonLabel">App Id</Typography>
              {userInfo.user.roleId === RoleType.Admin ||
              userInfo.user.roleId === RoleType.SuperAdmin ? (
                <Link
                  href="/"
                  onClick={(e) => {
                    debugger;
                    e.preventDefault(); // Prevent appending #
                    navigate(
                      `/userInformation?ref=${props.refferal.userId.toUpperCase()}`,
                      {
                        replace: true,
                      }
                    );
                  }}
                >
                  {props.refferal.appId}
                </Link>
              ) : (
                <Typography className="commonValue">
                  {props.refferal.appId}
                </Typography>
              )}
            </Box>
            <Box className="commonLabelValue">
              <Typography className="commonLabel">Joined On</Typography>
              <Typography className="commonValue">
                {_applicationHelperService.getExpandedDateTimeFormat(
                  props.refferal.createdOn
                )}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Grid>
  );
};

export default ReferralsComponent;
