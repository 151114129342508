import React, {
  FunctionComponent,
  useState,
  useContext,
  useEffect,
} from "react";
import { isReturnStatement } from "typescript";
import PageProps from "../../models/PageProps.interface";

//Libraries Import
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

//Modal
import Modal from "@mui/material/Modal";

//Icons Import
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

//Component Import
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";

//Import Css
import "../rechargeUser/rechargeUser.screen.style.css";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import UserService from "../../services/user/user.service";
import { ToastContext } from "../../context/toast.context";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { IUserResponseModel } from "../../interface/Response/IUserResponseModel";
import { LoadingContext } from "../../context/loading.context";
import { Form, Formik } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import CardActions from "@mui/material/CardActions";
import AlertDialog from "../../components/alertDialog/alertDialog.component";
import { IAlertDialogConfig } from "../../interface/IAlertDialogConfig";
import { IRechargeUserModel } from "../../interface/BusinessModels/IRechargeUserModel";
import * as yup from "yup";
import RechargeService from "../../services/recharge/recharge.service";
import { useLocation, useNavigate } from "react-router-dom";
import {
  RECHARGE,
  DEDUCT,
  API_ERROR_STANDARD_MESSAGE,
  DEFAULT_ALERT_CONFIG,
  RoleType,
} from "../../constants/DBConstants.constant";
import { HttpStatusCode } from "axios";
import { useDispatch, useSelector } from "react-redux";
import AuthService from "../../services/auth/auth.service";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { getWalletBalance } from "../../actions/lookup.actions";

const RechargeUser: FunctionComponent<PageProps> = ({ title }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchBy, setSearchBy] = useState<string>("2");
  const [searchText, setSearchText] = useState<string>("");
  const [activeResetId, setActiveResetId] = useState<number>(0);
  const { setToastConfig, setOpen } = useContext(ToastContext);
  const _userService = new UserService(dispatch);
  const _rechargeService = new RechargeService(dispatch);
  const _authService = new AuthService(dispatch);
  const [userDetail, setUserDetail] = useState<IUserResponseModel | null>(null);
  const { setLoading } = useContext(LoadingContext);
  const getUserWalletBalance = () => dispatch<any>(getWalletBalance());
  const { userInfo } = useSelector((state: any) => state.authReducer);
  const InitialModelConfig = {
    title: "",
    transactionType: "",
    isShow: false,
  };

  const [deductRechModelConfig, setDeductRechModelConfig] = React.useState(
    InitialModelConfig
  );

  useEffect(() => {
    document.title = title;
  }, []);

  const getSearchResults = async () => {
    setLoading(true);
    try {
      if (searchText) {
        console.log(searchText, " ", searchBy);
        var response: IApiResponse = await _userService.searchUsers(
          +searchBy,
          searchText
        );
        if (!response.isSuccess) {
          setToastConfig(ToastSeverity.Error, response.message, true);
          setUserDetail(null);
        } else {
          setUserDetail(response.response);
        }
      } else {
        setToastConfig(ToastSeverity.Error, "Value is required.", true);
      }
    } catch (error) {
      setUserDetail(null);
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
    }
    setLoading(false);
  };

  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });

  const RechargeUserInitialValues: IRechargeUserModel = {
    amount: 0,
    comments: "",
    userModel: null,
    transactionType: "",
  };

  const RechargeUserSchema = yup.object().shape({
    amount: yup
      .number()
      .required("Amount is required.")
      .transform((curr, orig) => (orig === 0 ? null : curr))
      .min(1, `Min amount should be 1`),
    comments: yup.string().required("Comments is required."),
  });

  const _handleRechargeUser = async (values: IRechargeUserModel) => {
    try {
      setLoading(true);
      values = {
        ...values,
        transactionType: deductRechModelConfig.title,
        userModel: userDetail,
      };
      var response: IApiResponse = await _rechargeService.rechargeUser(values);
      if (!response.isSuccess) {
        setToastConfig(ToastSeverity.Error, response.message, true);
      } else {
        if (response.response.status > 0) {
          setAlertConfig({
            description: "Transaction Successful",
            toastSeverity: ToastSeverity.Success,
            isShow: true,
            callBack: () => {
              setAlertConfig(DEFAULT_ALERT_CONFIG);
              setDeductRechModelConfig(InitialModelConfig);
              getSearchResults();
              getUserWalletBalance();
              // navigate("/agentRechargeList");
            },
          });
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
    }
  };

  const _resetPassword = async () => {
    try {
      if (activeResetId > 0) {
        setLoading(true);
        var response: IApiResponse = await _authService.resetPassword({
          appId: activeResetId + "",
        });
        if (!response.isSuccess) {
          setToastConfig(ToastSeverity.Error, response.message, true);
        } else {
          setActiveResetId(0);
          setAlertConfig({
            description: response.response.message,
            toastSeverity: ToastSeverity.Success,
            isShow: true,
            callBack: () => {
              setAlertConfig(DEFAULT_ALERT_CONFIG);
            },
          });
        }
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
    }
  };

  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          <Box className="pageViewBody commonScroll">
            <Box className="rechargeUser">
              <Card>
                <CardContent>
                  <Box className="saperatorSpacing">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Box>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Search By
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="From"
                              value={searchBy}
                              onChange={(evt: SelectChangeEvent) => {
                                setSearchBy(evt.target.value);
                                setUserDetail(null);
                                setSearchText("");
                              }}
                            >
                              {/* <MenuItem value={1}>Email</MenuItem> */}
                              <MenuItem value={2}>User Id</MenuItem>
                              {/* <MenuItem value={3}>User UI Id</MenuItem> */}
                              <MenuItem value={4}>Mobile</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <Box>
                          <TextField
                            id="value"
                            label="Value"
                            variant="outlined"
                            fullWidth
                            value={searchText}
                            onChange={(event: any) =>
                              setSearchText(event.target.value)
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <Button
                          variant="contained"
                          className="primaryFillBtn width100"
                          onClick={() => {
                            getSearchResults();
                          }}
                        >
                          <span>Get Results</span>
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                  {userDetail && (
                    <Box className="commonBgCard commonBgColorCard">
                      <Box className="saperatorSpacing">
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          style={{ marginBottom: 20 }}
                        >
                          <Box>
                            <Stack spacing={2} direction="row">
                              <Button
                                variant="contained"
                                className="primaryFillBtn"
                                onClick={() =>
                                  setDeductRechModelConfig({
                                    title: RECHARGE,
                                    isShow: true,
                                    transactionType: RECHARGE,
                                  })
                                }
                              >
                                <span>Recharge</span>
                              </Button>
                              {userInfo.user.roleId !== RoleType.Agent &&
                                userInfo.user.roleId !== RoleType.User && (
                                  <Stack spacing={2} direction="row">
                                    <Button
                                      variant="outlined"
                                      className="primaryLineBtn"
                                      onClick={() =>
                                        setDeductRechModelConfig({
                                          title: DEDUCT,
                                          isShow: true,
                                          transactionType: DEDUCT,
                                        })
                                      }
                                    >
                                      <span>Deduct</span>
                                    </Button>
                                    <Button
                                      variant="outlined"
                                      className="primaryLineBtn"
                                      onClick={() =>
                                        setActiveResetId(+userDetail.appId)
                                      }
                                    >
                                      <span>Reset Password</span>
                                    </Button>
                                    <Button
                                      variant="outlined"
                                      className="primaryLineBtn"
                                      onClick={() =>
                                        navigate(
                                          `/userInformation?ref=${userDetail.userId.toUpperCase()}`,
                                          {
                                            replace: true,
                                          }
                                        )
                                      }
                                    >
                                      <span>View Transactions</span>
                                    </Button>
                                  </Stack>
                                )}
                            </Stack>
                          </Box>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12} lg={12}>
                            <Typography className="bannerTitle">
                              User Details
                            </Typography>
                          </Grid>
                        </Grid>
                        <Box>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={6}>
                              {/* <Box className="commonLabelValue nextLabelValue">
                                <Typography className="commonLabel">
                                  Id
                                </Typography>
                                <Typography className="commonValue">
                                  {userDetail.userId}
                                </Typography>
                              </Box> */}
                              <Box className="commonLabelValue nextLabelValue">
                                <Typography className="commonLabel">
                                  User Id
                                </Typography>
                                <Typography className="commonValue">
                                  {userDetail.appId}
                                </Typography>
                              </Box>
                              <Box className="commonLabelValue nextLabelValue">
                                <Typography className="commonLabel">
                                  Email
                                </Typography>
                                <Typography className="commonValue">
                                  {userDetail.email}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                              <Box className="commonLabelValue nextLabelValue">
                                <Typography className="commonLabel">
                                  Available Amount
                                </Typography>
                                <Typography className="commonValue">
                                  {userDetail.walletBalance.balance}
                                </Typography>
                              </Box>
                            </Grid>
                            {userInfo.user.roleId !== RoleType.Agent &&
                              userInfo.user.roleId !== RoleType.User && (
                                <>
                                  <Grid item xs={12} md={6} lg={6}>
                                    <Box className="commonLabelValue nextLabelValue">
                                      <Typography className="commonLabel">
                                        Phone Code
                                      </Typography>
                                      <Typography className="commonValue">
                                        {userDetail.phoneCode}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={6}>
                                    <Box className="commonLabelValue nextLabelValue">
                                      <Typography className="commonLabel">
                                        Phone Number
                                      </Typography>
                                      <Typography className="commonValue">
                                        {userDetail.phoneNumber}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </>
                              )}
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Box>

            {/* Modal Popup Starts Here - Recharge Money*/}
            <Modal
              className="commonModal"
              open={deductRechModelConfig.isShow}
              onClose={() => setDeductRechModelConfig(InitialModelConfig)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Formik
                initialValues={{ ...RechargeUserInitialValues }}
                validationSchema={RechargeUserSchema}
                onSubmit={(values, { setSubmitting }) => {
                  _handleRechargeUser(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValid,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit} className="height100">
                    <Box className="commonModalBox">
                      <Box className="commonModalBoxInner">
                        <Box id="modal-modal-title" className="commonModalHead">
                          <Grid container spacing={2}>
                            <Grid item xs={11} md={11} lg={11}>
                              <Typography className="modalTitle">
                                {deductRechModelConfig.title
                                  .substring(0, 1)
                                  .toUpperCase() +
                                  deductRechModelConfig.title
                                    .substring(
                                      1,
                                      deductRechModelConfig.title.length
                                    )
                                    .toLowerCase()}{" "}
                                Money
                              </Typography>
                            </Grid>
                            <Grid item xs={1} md={1} lg={1}>
                              <span className="modalClose">
                                <CloseRoundedIcon
                                  onClick={() =>
                                    setDeductRechModelConfig(InitialModelConfig)
                                  }
                                />
                              </span>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box
                          id="modal-modal-description"
                          className="commonModalBody"
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={12}>
                              <Box>
                                <TextField
                                  id="amount"
                                  label="Enter Amount"
                                  variant="outlined"
                                  fullWidth
                                  onChange={(e) => {
                                    const re = /^[0-9\b]+$/;
                                    if (
                                      e.target.value === "" ||
                                      re.test(e.target.value)
                                    ) {
                                      setFieldValue(`amount`, e.target.value);
                                    }
                                  }}
                                />
                                {errors.amount && touched.amount && (
                                  <FormHelperText className="errorMessage">
                                    {errors.amount}
                                  </FormHelperText>
                                )}
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                              <Box>
                                <TextField
                                  id="comments"
                                  label="Enter Comments"
                                  variant="outlined"
                                  fullWidth
                                  onChange={handleChange}
                                />
                                {errors.comments && touched.comments && (
                                  <FormHelperText className="errorMessage">
                                    {errors.comments}
                                  </FormHelperText>
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box id="modal-modal-footer" className="commonFooter">
                          <Stack spacing={2} direction="row">
                            <Button
                              variant="outlined"
                              className="primaryLineBtn"
                              onClick={() =>
                                setDeductRechModelConfig(InitialModelConfig)
                              }
                            >
                              <span>Cancel</span>
                            </Button>
                            <Button
                              variant="contained"
                              className="primaryFillBtn"
                              type="submit"
                            >
                              <span>{deductRechModelConfig.title}</span>
                            </Button>
                          </Stack>
                        </Box>
                      </Box>
                    </Box>
                  </form>
                )}
              </Formik>
            </Modal>
          </Box>
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
      </Box>
      {alertConfig && alertConfig.isShow && (
        <AlertDialog
          alertConfig={alertConfig}
          callBack={alertConfig.callBack}
        />
      )}
      <Dialog
        open={activeResetId > 0}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Reset Password"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure want to reset the password for User {activeResetId}.?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className="primaryLineBtn"
            onClick={() => setActiveResetId(0)}
          >
            <span>Cancel</span>
          </Button>
          <Button
            variant="contained"
            className="primaryFillBtn"
            onClick={() => _resetPassword()}
          >
            <span>Confirm</span>
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default RechargeUser;
