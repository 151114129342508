import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import ApplicationHelperService from "../../services/ApplicationHelperService";

const CountdownTimer = (props: { date: Date }) => {
  const _applicationHelperService = new ApplicationHelperService({});
  const targetDate = props.date; // Set the target date (example)

  const getRemainingTime = () => {
    // const utcDate = new Date().toISOString();
    // var endDateTime = utcDate.split("T");
    // var endDateArray = endDateTime[0].split("-");
    // var endTimeArray = endDateTime[1].split(":");
    // const endUtcDateTime = new Date(
    //   +endDateArray[0], // Year
    //   +endDateArray[1] - 1, // Month
    //   +endDateArray[2], // Dates
    //   +endTimeArray[0], // Hour
    //   +endTimeArray[1] // Minutes
    // );

    // return props.date.getTime() - endUtcDateTime.getTime();

    const nowUtc = new Date(); // Get current UTC time correctly
    return targetDate.getTime() - nowUtc.getTime();
  };

  const [remainingTime, setRemainingTime] = useState<number>(0);

  useEffect(() => {
    // Update the countdown every second
    const interval = setInterval(() => {
      const newRemainingTime = getRemainingTime();
      setRemainingTime(newRemainingTime);
      // If the countdown is over, stop updating
      if (newRemainingTime <= 0) {
        clearInterval(interval);
      }
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [targetDate]);

  const RemainingTimeWindow = (props: { timeRemain: number }) => {
    const {
      days,
      hours,
      minutes,
      seconds,
    } = _applicationHelperService.formatTime(props.timeRemain);

    return (
      <Typography variant="h5" color={"green"} style={{ textAlign: "center" }}>
        Ends in {days > 0 && `${days} Days `} {/* Conditionally render days */}
        {hours > 0 && `${hours} Hours `}
        {minutes} Mins {seconds} Secs
      </Typography>
    );
  };

  return remainingTime < 0 ? (
    <Typography variant="h5" color="red" style={{ textAlign: "center" }}>
      Game Ended
    </Typography>
  ) : (
    <RemainingTimeWindow timeRemain={remainingTime} />
  );
};

export default CountdownTimer;
